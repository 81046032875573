//import Vue from 'vue'
import {createApp} from 'vue'
import App from './App.vue'
const appInstance = createApp(App);

// import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/css/animate.min.css'
import './assets/css/style.css'
import './assets/css/modal.css'
import '@fortawesome/fontawesome-free/css/all.min.css'

//Vue.config.productionTip = false

import router from './routes'

import store from './store'

// custom pipes
//import './pipes'
import pipes from './pipes.js'
appInstance.config.globalProperties.$filters = pipes
// masks
import VueTheMask from 'vue-the-mask'
appInstance.use(VueTheMask)

// loading
import Load from './components/uicomponents/Load'
appInstance.component('Load', Load)

import Dropdown from './components/uicomponents/Dropdown.vue'
appInstance.component('drop-down', Dropdown)

import JsonExcel from "vue-json-excel"
appInstance.component("downloadExcel", JsonExcel)



/*import Vuelidate from '@vuelidate/core'
appInstance.use(Vuelidate)*/

/*import VueSweetalert2 from 'vue-sweetalert2';
 
// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';
const options = {
  showClass: {
    popup: 'animated fadeInDown faster'
  },
  confirmButtonColor: '#41b882',
  cancelButtonColor: '#ff7674',
}
appInstance.use(VueSweetalert2, options);*/
import VueSweetalert2 from "vue-sweetalert2";
appInstance.use(VueSweetalert2);


/*new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')*/


appInstance.use(router).use(store).mount('#app')